import type {
  Instance as PopperInstance,
  Options as PopperOptions,
} from "@popperjs/core";
import { createPopper } from "@popperjs/core";
import type { TooltipInstance, TooltipOptions } from "./types";
import { TooltipInterface } from "./interface";

const Default: TooltipOptions = {
  placement: "top",
  triggerType: "hover",
  isOpen: false,
  onShow: () => {},
  onHide: () => {},
  onToggle: () => {},
};

class Tooltip implements TooltipInterface {
    _targetEl: HTMLElement | null;
    _triggerEl: HTMLElement | null;
    _options: TooltipOptions;
    _popperInstance: PopperInstance;
    _clickOutsideEventListener: EventListenerOrEventListenerObject;
    _keydownEventListener: EventListenerOrEventListenerObject;
    _visible: boolean;
    _isOpen : boolean;

    constructor(
        targetEl: HTMLElement | null = null,
        triggerEl: HTMLElement | null = null,
        options: TooltipOptions = Default
    ) {
        this._targetEl = targetEl;
        this._triggerEl = triggerEl;
        this._options = { ...Default, ...options };
        this._popperInstance = this._createPopperInstance();
        this._visible = false;
        this._isOpen = options.isOpen;
        this._init();
    }

    _init() {
        if (this._triggerEl) {
            this._setupEventListeners();
        }

        if (this._isOpen) {
            this.show();
        }
    }

    _setupEventListeners() {
        const triggerEvents = this._getTriggerEvents();
        triggerEvents.showEvents.forEach((ev) => {
            this._triggerEl.addEventListener(ev, () => {
                this.show();
            });
        });
        triggerEvents.hideEvents.forEach((ev) => {
            this._triggerEl.addEventListener(ev, () => {
                this.hide();
            });
        });
    }

    _createPopperInstance() {
        return createPopper(this._triggerEl, this._targetEl, {
            placement: this._options.placement,
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 8],
                    },
                },
            ],
        });
    }

    _getTriggerEvents() {
        switch (this._options.triggerType) {
            case 'hover':
                return {
                    showEvents: ['mouseenter', 'focus'],
                    hideEvents: ['mouseleave', 'blur'],
                };
            case 'click':
                return {
                    showEvents: ['click', 'focus'],
                    hideEvents: ['focusout', 'blur'],
                };
            case 'none':
                return {
                    showEvents: [],
                    hideEvents: [],
                };
            default:
                return {
                    showEvents: ['mouseenter', 'focus'],
                    hideEvents: ['mouseleave', 'blur'],
                };
        }
    }

    _setupKeydownListener() {
        this._keydownEventListener = (ev: KeyboardEvent) => {
            if (ev.key === 'Escape') {
                this.hide();
            }
        };
        document.body.addEventListener(
            'keydown',
            this._keydownEventListener,
            true
        );
    }

    _removeKeydownListener() {
        document.body.removeEventListener(
            'keydown',
            this._keydownEventListener,
            true
        );
    }

    _setupClickOutsideListener() {
        this._clickOutsideEventListener = (ev: MouseEvent) => {
            this._handleClickOutside(ev, this._targetEl);
        };
        document.body.addEventListener(
            'click',
            this._clickOutsideEventListener,
            true
        );
    }

    _removeClickOutsideListener() {
        document.body.removeEventListener(
            'click',
            this._clickOutsideEventListener,
            true
        );
    }

    _handleClickOutside(ev: Event, targetEl: HTMLElement) {
        const clickedEl = ev.target as Node;
        if (
            clickedEl !== targetEl &&
            !targetEl.contains(clickedEl) &&
            !this._triggerEl.contains(clickedEl) &&
            this.isVisible()
        ) {
            this.hide();
        }
    }

    isVisible() {
        return this._visible;
    }

    toggle() {
        if (this.isVisible()) {
            this.hide();
        } else {
            this.show();
        }
    }

    show() {
        this._targetEl.classList.remove('!tw-opacity-0', '!tw-invisible');
        this._targetEl.classList.add('!tw-opacity-100', '!tw-visible');

        // Enable the event listeners
        this._popperInstance.setOptions((options: PopperOptions) => ({
            ...options,
            modifiers: [
                ...options.modifiers,
                { name: 'eventListeners', enabled: true },
            ],
        }));

        if (!this._isOpen) {
            // handle click outside
            this._setupClickOutsideListener();

            // handle esc keydown
            this._setupKeydownListener();
        }

        // Update its position
        this._popperInstance.update();

        // set visibility
        this._visible = true;

        // callback function
        this._options.onShow(this);
    }

    hide() {
        this._targetEl.classList.remove('!tw-opacity-100', '!tw-visible');
        this._targetEl.classList.add('!tw-opacity-0', '!tw-invisible');

        // Disable the event listeners
        this._popperInstance.setOptions((options: PopperOptions) => ({
            ...options,
            modifiers: [
                ...options.modifiers,
                { name: 'eventListeners', enabled: false },
            ],
        }));

        // handle click outside
        this._removeClickOutsideListener();

        // handle esc keydown
        this._removeKeydownListener();

        // set visibility
        this._visible = false;

        // callback function
        this._options.onHide(this);
    }
}

if (typeof window !== 'undefined') {
    window.Tooltip = Tooltip;
}

const getTooltipInstance = (id: string, instances: TooltipInstance[]) => {
    if (instances.some((tooltipInstance) => tooltipInstance.id === id)) {
        return instances.find((tooltipInstance) => tooltipInstance.id === id);
    }
    return null;
};

export function initTooltips() {
    const tooltipInstances = [] as TooltipInstance[];

    // initiate tooltip based on data-tooltip-target
    document.querySelectorAll('[data-tooltip-target]').forEach(($triggerEl) => {
        const tooltipId = $triggerEl.getAttribute('data-tooltip-target');
        const $tooltipEl = document.getElementById(tooltipId);
        var tooltipIsShow = !!$triggerEl.getAttribute(
            "data-tooltip-is-open"
        );

        if ($tooltipEl) {
            const triggerType = $triggerEl.getAttribute('data-tooltip-trigger');
            const placement = $triggerEl.getAttribute('data-tooltip-placement');
            new Tooltip(
                $tooltipEl as HTMLElement,
                $triggerEl as HTMLElement,
                {
                    placement: placement ? placement : Default.placement,
                    triggerType: triggerType
                        ? triggerType
                        : Default.triggerType,
                    isOpen: !!tooltipIsShow
                } as TooltipOptions
            );

            if (!getTooltipInstance(tooltipId, tooltipInstances)) {
                tooltipInstances.push({
                    id: tooltipId,
                    object: new Tooltip(
                        $tooltipEl as HTMLElement,
                        $triggerEl as HTMLElement,
                        {
                            placement: placement
                                ? placement
                                : Default.placement,
                            triggerType: triggerType
                                ? triggerType
                                : Default.triggerType,
                            isOpen: !!tooltipIsShow,
                        } as TooltipOptions
                    ),
                });
            }

        } else {
            console.error(
                `The tooltip element with id "${tooltipId}" does not exist. Please check the data-tooltip-target attribute.`
            );
        }
    });

    // hide tooltip on click if exists based on id
    document.querySelectorAll('[data-tooltip-hide]').forEach(($triggerEl) => {
        const tooltipId = $triggerEl.getAttribute('data-tooltip-hide');
        const $tooltipEl = document.getElementById(tooltipId);
        if ($tooltipEl) {
            const tooltip: TooltipInstance = getTooltipInstance(
                tooltipId,
                tooltipInstances
            );
            if (tooltip) {
                $triggerEl.addEventListener('click', () => {
                    if (tooltip.object.isVisible) {
                        tooltip.object.hide();
                        console.log('ok')
                    }
                });
            } else {
                console.error(
                    `Modal with id ${tooltipId} has not been initialized. Please initialize it using the data-modal-target attribute.`
                );
            }
        } else {
            console.error(
                `Modal with id ${tooltipId} does not exist. Are you sure that the data-modal-hide attribute points to the correct modal id?`
            );
        }
    });
}

export default Tooltip;
